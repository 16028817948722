import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import "./style.css";

// import Testimonials from "../Home/Testimonials";
// import GetInTouch from "../Home/GetInTouch";

const GrievancePolicy = () => {
  useEffect(() => {
    document.title = "Grievance Policy";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* Breadcrumb */}
        <div className="rts-breadcrumb-area body-bg-2">
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="row align-items-center">
                <div className="col-lg-6 order-change">
                  <div className="breadcrumb-content">
                    <h1 className="heading-title" style={{ maxWidth: 494 }}>
                      Grievance Policy
                    </h1>
                    <p className="desc" style={{ maxWidth: 468 }}></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="breadcrumb-image-area">
                    <img
                      src="assets/images/banner/breadcrumb-07.webp"
                      width={515}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb-shape-area">
            <img src="assets/images/banner/breadcrumb-shape.svg" alt="" />
          </div>
        </div>
        {/* Breadcrumb END */}

        <>
          {/* Blog Details Block */}
          <section class="rts-knowledgebase pt--60 pb--60">
            <div class="container">
              <div className="section_title">
                <h4>
                  Grievance Policy for Bongpe Payment Solution Private Limited
                </h4>

                <div className="info pt-3">
                  <p>
                    In accordance with Information Technology Act 2000 and rules
                    made there under, contact details of the Grievance Officer
                    are provided below:
                  </p>
                  <p>Name: Mr. Binoy Biswas</p>
                  <p className="pt-2">
                    Address: H.O : HOUSE NO 2 LAKE PARA, ISHANPUR Sidhai,
                    Sidhai, West Tripura, Tripura - 799212
                  </p>
                  <p>Mobile: +91 8257-9544-02</p>
                  <p className="pt-2">Email: info@bongpe.com</p>
                </div>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default GrievancePolicy;
