import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <>
        {/* FOOTER AREA */}
        <footer className="rts-footer site-footer-one section__padding body-bg-2">
          <div className="container">
            <div className="row">
              {/* widget */}
              <div className="col-lg-4 col-md-6 col-sm-6 rts-footer__widget--column">
                <div className="rts-footer__widget footer__widget w-280">
                  <a
                    href="/"
                    aria-label="main page link"
                    className="footer__logo"
                  >
                    <img
                      src="/images/logo.png"
                      width={150}
                      alt="rupe-pro-logo"
                    />
                  </a>
                  <p className="brand-desc">
                    H.O : HOUSE NO 2 LAKE PARA, ISHANPUR Sidhai, Sidhai, West
                    Tripura, Tripura - 799212
                  </p>
                  <div className="contact-wrapper">
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="fa-regular fa-phone" />
                        </div>
                        <a href="+918257954402">+91 8257-9544-02</a>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="fa-sharp fa-regular fa-envelope" />
                        </div>
                        <a href="info@bongpe.com">info@bongpe.com</a>
                      </li>
                    </ul>
                  </div>
                  <div className="separator site-default-border" />
                  <div className="payment__method">
                    <ul>
                      <li>
                        <img src="assets/images/payment/visa.svg" alt="" />
                      </li>
                      <li>
                        <img
                          src="assets/images/payment/master-card.svg"
                          alt=""
                        />
                      </li>
                      <li>
                        <img src="assets/images/payment/paypal.svg" alt="" />
                      </li>
                      <li>
                        <img
                          src="assets/images/payment/american-express.svg"
                          alt=""
                        />
                      </li>
                      {/* <li>
                        <img src="assets/images/payment/wise.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/images/payment/skrill.svg" alt="" />
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* widget end */}
              {/* widget */}
              <div className="col-lg-2 col-md-3 col-sm-6 rts-footer__widget--column">
                <div className="footer-widget-inner">
                  <div className="rts-footer__widget footer__widget extra-padding">
                    <h5 className="widget-title">Services</h5>
                    <div className="rts-footer__widget--menu ">
                      <ul>
                        <li>
                          <a href="/our_services">Mobile Recharge</a>
                        </li>
                        <li>
                          <a href="/our_services">DTH Recharge</a>
                        </li>
                        <li>
                          <a href="/our_services">Google Play Recharge</a>
                        </li>
                        <li>
                          <a href="/our_services">Utility Bill Payments</a>
                        </li>
                        {/* <li>
                          <a href="/our_services">PAN Card Service</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* widget end */}
              {/* widget */}
              <div className="col-lg-2 col-md-3 col-sm-6 rts-footer__widget--column">
                <div className="footer-widget-inner">
                  <div className="rts-footer__widget footer__widget extra-padding">
                    <h5 className="widget-title">Quick Links</h5>
                    <div className="rts-footer__widget--menu ">
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about_us">About Us</a>
                        </li>
                        <li>
                          <a href="/contact_us">Contact Us</a>
                        </li>
                        <li>
                          <a href="/terms_and_conditions">
                            Terms and Conditions
                          </a>
                        </li>
                        <li>
                          <a href="/privacy_policy">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="/refund_policy">Refund Policy</a>
                        </li>
                        <li>
                          <a href="/grievance_policy">Grievance Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* widget end */}
              {/* widget */}
              <div className="col-lg-4 col-md-6 rts-footer__widget--column">
                <div className="rts-footer__widget footer__widget w-307 ml--auto">
                  <h5 className="widget-title">Join Our Newsletter</h5>
                  <p>We'll send you news and offers.</p>
                  <form action="#" className="newsletter mx-40">
                    <input
                      type="email"
                      className="home-one"
                      name="email"
                      placeholder="Enter mail"
                      required=""
                    />
                    <span className="icon">
                      <i className="fa-regular fa-envelope-open" />
                    </span>
                    <button type="button" aria-label="Submit">
                      <i className="fa-regular fa-arrow-right" />
                    </button>
                  </form>
                  <div className="social__media">
                    <h5>social media</h5>
                    <div className="social__media--list">
                      <a
                        href="#"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                      <a
                        href="#"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                      <a
                        href="#"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-linkedin" />
                      </a>
                      <a
                        href="https://www.youtube.com/@teamtechsupportbinoy"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-youtube" />
                      </a>
                      <a
                        href="https://t.me/teamtechsupportofficial"
                        aria-label="social-link"
                        target="_blank"
                        className="media"
                      >
                        <i className="fa-brands fa-telegram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* widget end */}

              <div className="pt-4">
                <p>CIN: U66190TR2023PTC014242 | GSTIN: 16AALCB7052M1ZA</p>
              </div>
            </div>
          </div>
          <div className="rts__footer__copyright is__common">
            <div className="container">
              <div className="row">
                <div className="footer__copy__wrapper justify-content-center text-center">
                  <p>
                    © 2024 Bongpe Payment Solution Private Limited. All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* FOOTER AREA END */}
        <div id="anywhere-home" className=""></div>
        {/* side bar area  */}
        <div id="side-bar" className="side-bar header-two">
          <button className="close-icon-menu" aria-label="Close Menu">
            <i className="fa-sharp fa-thin fa-xmark" />
          </button>
          {/* mobile menu area start */}
          <div className="mobile-menu-main">
            <nav className="nav-main mainmenu-nav mt--30">
              <ul className="mainmenu metismenu" id="mobile-menu-active">
                <li className="has-droupdown">
                  <a href="#" className="main">
                    Home
                  </a>
                  <ul className="submenu mm-collapse">
                    <li>
                      <a className="mobile-menu-link" href="index.html">
                        Home One
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="index-two.html">
                        Home Two
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="index-three.html">
                        Home Three
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="index-four.html">
                        Home Four
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="index-five.html">
                        Home Five
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="index-six.html">
                        Home Six
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <a href="#" className="main">
                    Pages
                  </a>
                  <ul className="submenu mm-collapse">
                    <li>
                      <a className="mobile-menu-link" href="about.html">
                        About
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="faq.html">
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="book-a-demo.html">
                        Sign Up
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="blog.html">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="blog-list.html">
                        Blog List
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="support.html">
                        Support
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="pricing.html">
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="signin.html">
                        Sign In
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="knowledgebase.html">
                        Knowledgebase
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="blog-details.html">
                        Blog Details
                      </a>
                    </li>
                    <li>
                      <a
                        className="mobile-menu-link"
                        href="domain-checker.html"
                      >
                        Domain Checker
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="contact.html">
                        Contact
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <a href="#" className="main">
                    Hosting
                  </a>
                  <ul className="submenu mm-collapse">
                    <li>
                      <a
                        className="mobile-menu-link"
                        href="shared-hosting.html"
                      >
                        Shared Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        className="mobile-menu-link"
                        href="wordpress-hosting.html"
                      >
                        WordPress Hosting
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="vps-hosting.html">
                        VPS Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        className="mobile-menu-link"
                        href="reseller-hosting.html"
                      >
                        Reseller Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        className="mobile-menu-link"
                        href="dedicated-hosting.html"
                      >
                        Dedicated Hosting
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="cloud-hosting.html">
                        Cloud Hosting
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <a href="#" className="main">
                    Feature
                  </a>
                  <ul className="submenu mm-collapse">
                    <li>
                      <a
                        className="mobile-menu-link"
                        target="_blank"
                        href="https://hostie-whmcs.themewant.com/?systpl=elitehost"
                      >
                        WHMCS Template
                      </a>
                    </li>
                    <li>
                      <a
                        className="mobile-menu-link"
                        target="_blank"
                        href="https://hostie-whmcs.themewant.com/index.php/store/shared-hosting?systpl=elitehost"
                      >
                        Shared Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        className="mobile-menu-link"
                        target="_blank"
                        href="https://hostie-whmcs.themewant.com/index.php/store/vps-hosting?systpl=elitehost"
                      >
                        VPS Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        className="mobile-menu-link"
                        target="_blank"
                        href="https://hostie-whmcs.themewant.com/index.php/announcements?systpl=elitehost"
                      >
                        Announcment
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <a href="#" className="main">
                    Help Center
                  </a>
                  <ul className="submenu mm-collapse">
                    <li>
                      <a className="mobile-menu-link" href="knowledgebase.html">
                        Knowledgebase
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="support.html">
                        Support
                      </a>
                    </li>
                    <li>
                      <a className="mobile-menu-link" href="contact.html">
                        Contact
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <ul className="social-area-one pl--20 mt--100">
              <li>
                <a
                  href="https://www.linkedin.com/"
                  aria-label="social-link"
                  target="_blank"
                >
                  <i className="fa-brands fa-linkedin" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.x.com/"
                  aria-label="social-link"
                  target="_blank"
                >
                  <i className="fa-brands fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/"
                  aria-label="social-link"
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/"
                  aria-label="social-link"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook-f" />
                </a>
              </li>
            </ul>
          </div>
          {/* mobile menu area end */}
        </div>
        {/* side abr area end */}
        {/* THEME PRELOADER START */}
        <div className="loader-wrapper">
          <div className="loader"></div>
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
        {/* THEME PRELOADER END */}
        {/* BACK TO TOP AREA START */}
        <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path
              d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
              style={{
                transition: "stroke-dashoffset 10ms linear 0s",
                strokeDasharray: "307.919, 307.919",
                strokeDashoffset: "307.919",
              }}
            />
          </svg>
        </div>
        {/* BACK TO TOP AREA EDN */}
      </>
    </React.Fragment>
  );
};

export default Footer;
