import React from "react";

const WhyChooseUs = () => {
  return (
    <React.Fragment>
      {/* Elitehost FEATURE AREA */}
      <section class="rts-feature section__padding body-bg-2">
        <div className="container">
          <div className="row">
            <div className="rts-section text-center">
              <h2 className="rts-section__title">Why RupePro</h2>
            </div>
          </div>
          <div className="row gy-30">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single__feature">
                <div className="single__feature--box">
                  <div className="single__feature--box-icon">
                    <img src="assets/images/feature/feature-01.svg" alt="" />
                  </div>
                  <h5 className="single__feature--box-title">
                    Comprehensive Services
                  </h5>
                  <p className="single__feature--box-description">
                    Offer a wide range of digital services, including recharges,
                    bill payments, insurance, and PAN card solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single__feature">
                <div className="single__feature--box">
                  <div className="single__feature--box-icon">
                    <img src="assets/images/feature/feature-02.svg" alt="" />
                  </div>
                  <h5 className="single__feature--box-title">
                    Secure and Reliable
                  </h5>
                  <p className="single__feature--box-description">
                    All transactions are encrypted and protected, ensuring
                    maximum security and trust.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single__feature">
                <div className="single__feature--box">
                  <div className="single__feature--box-icon">
                    <img src="assets/images/feature/feature-03.svg" alt="" />
                  </div>
                  <h5 className="single__feature--box-title">
                    Scalable Solutions
                  </h5>
                  <p className="single__feature--box-description">
                    Whether you’re a small retailer or a growing business,
                    RupePro offers scalable solutions to fit your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single__feature">
                <div className="single__feature--box">
                  <div className="single__feature--box-icon">
                    <img src="assets/images/feature/feature-04.svg" alt="" />
                  </div>
                  <h5 className="single__feature--box-title">24/7 Support</h5>
                  <p className="single__feature--box-description">
                    Dedicated customer support available round-the-clock to
                    assist with any queries or issues.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default WhyChooseUs;
