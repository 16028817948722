import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";
import ServicesBoxIcon from "./ServicesBoxIcon";
import WhyChooseUs from "../Home/WhyChooseUs";
import Faq from "../Home/Faq";

const OurServices = () => {
  useEffect(() => {
    document.title = "Our Services - RupePro";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* Breadcrumb */}
        <div className="rts-breadcrumb-area body-bg-2">
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="row align-items-center">
                <div className="col-lg-6 order-change">
                  <div className="breadcrumb-content">
                    <h1 className="heading-title" style={{ maxWidth: 494 }}>
                      Our Services
                    </h1>
                    <p className="desc" style={{ maxWidth: 468 }}>
                      Comprehensive Digital Solutions Tailored for Your Business
                      Needs
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="breadcrumb-image-area">
                    <img
                      src="assets/images/banner/breadcrumb-01.webp"
                      width={515}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb-shape-area">
            <img src="assets/images/banner/breadcrumb-shape.svg" alt="" />
          </div>
        </div>
        {/* Breadcrumb END */}

        <ServicesBoxIcon />

        <WhyChooseUs />

        <Faq />

        <div className="pt--100"></div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default OurServices;
