import React from "react";

const Faq = () => {
  return (
    <React.Fragment>
      <section className="rts-hosting-faq pt--100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="rts-section text-center">
                <h2 className="rts-section__title mb-0">
                  Frequently asked questions
                </h2>
              </div>
              <div className="rts-faq__accordion">
                <div className="accordion accordion-flush" id="rts-accordion">
                  <div className="accordion-item active">
                    <div className="accordion-header" id="first">
                      <h4
                        className="accordion-button collapse show"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__one"
                        aria-expanded="false"
                        aria-controls="item__one"
                      >
                        What services does RupePro offer?
                      </h4>
                    </div>
                    <div
                      id="item__one"
                      className="accordion-collapse collapse collapse show"
                      aria-labelledby="first"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        RupePro provides Mobile Recharge, DTH Recharge, Google
                        Play Recharge, Utility Bill Payments, Insurance
                        Services, and PAN Card services for retailers.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="two">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__two"
                        aria-expanded="false"
                        aria-controls="item__two"
                      >
                        How can I register as a retailer with RupePro?
                      </h4>
                    </div>
                    <div
                      id="item__two"
                      className="accordion-collapse collapse"
                      aria-labelledby="two"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        You can register by contacting us through our website or
                        by reaching out to our support team at info@bongpe.com
                        or +91 8257954402.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="three">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__three"
                        aria-expanded="false"
                        aria-controls="item__three"
                      >
                        How do I earn commissions?
                      </h4>
                    </div>
                    <div
                      id="item__three"
                      className="accordion-collapse collapse"
                      aria-labelledby="three"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Retailers earn instant commissions on every successful
                        transaction, including recharges, bill payments, and PAN
                        card services.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="four">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__four"
                        aria-expanded="false"
                        aria-controls="item__four"
                      >
                        Is the platform secure for transactions?
                      </h4>
                    </div>
                    <div
                      id="item__four"
                      className="accordion-collapse collapse"
                      aria-labelledby="four"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Yes, RupePro uses advanced encryption and secure payment
                        gateways to ensure all transactions are safe and
                        reliable.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="five">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__five"
                        aria-expanded="false"
                        aria-controls="item__four"
                      >
                        Is there customer support available?
                      </h4>
                    </div>
                    <div
                      id="item__five"
                      className="accordion-collapse collapse"
                      aria-labelledby="five"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Yes, RupePro offers 24/7 customer support to assist with
                        any queries or issues.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rts-hosting-feature-area-2 section__padding">
        <div className="container">
          <div className="section-inner">
            <div className="row g-5">
              <div className="col-xl-4 col-lg-6">
                <div className="feature-wrapper">
                  <div className="icon">
                    <img src="assets/images/feature/feature-08.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4 className="title">Live Chat Support</h4>
                    <p className="desc">
                      Get instant assistance through our live chat feature
                      available 24/7. Our support agents are ready to help with
                      any queries or issues in real time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6">
                <div className="feature-wrapper">
                  <div className="icon">
                    <img src="assets/images/feature/feature-12.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4 className="title">Phone Support</h4>
                    <p className="desc">
                      Speak directly with our customer service team for
                      immediate support. Call us at +91 8257954402 or +91
                      9083299244 for prompt assistance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6">
                <div className="feature-wrapper">
                  <div className="icon">
                    <img src="assets/images/feature/feature-11.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4 className="title">Email Support</h4>
                    <p className="desc">
                      Reach out to us via email at info@bongpe.com for detailed
                      inquiries or support requests. Our team will respond
                      quickly to address your concerns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Faq;
