import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  const headerType = props.headerType ? props.headerType : "";

  const [headerTypeCss, setHeaderTypeCss] = useState(
    "header-nav-box header-nav-box-6"
  );

  useEffect(() => {
    if (headerType)
      setHeaderTypeCss(
        "header-nav-box header-nav-box-inner-page header-nav-box-3"
      );
  }, [headerType]);

  // if (headerType == "white_header") {
  //   setHeaderTypeCss(
  //     "header-nav-box header-nav-box-inner-page header-nav-box-3"
  //   );
  // }
  return (
    <React.Fragment>
      <>
        {/* HEADER AREA */}
        <header className="rts-header style-two header__default">
          <div className="container">
            <div className="row">
              <div className="rts-header__wrapper">
                <div className="header-wrapper-left d-flex align-items-center ">
                  {/* FOR LOGO */}
                  <div className="rts-header__logo">
                    <a href="/" className="site-logo">
                      <img
                        className="logo-white"
                        src="/images/logo.png"
                        alt="rupepro-logo"
                        width={130}
                      />
                      <img
                        className="logo-dark"
                        src="/images/logo.png"
                        alt="rupepro-logo"
                        width={130}
                      />
                    </a>
                  </div>
                  {/* FOR NAVIGATION MENU */}
                  <nav className="rts-header__menu" id="mobile-menu">
                    <div className="elitehost-menu">
                      <ul className="list-unstyled elitehost-desktop-menu">
                        <li className="menu-item">
                          <a
                            href="/"
                            className="elitehost-dropdown-main-element"
                          >
                            Home
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/about_us"
                            className="elitehost-dropdown-main-element"
                          >
                            About Us
                          </a>
                        </li>
                        <li className="menu-item elitehost-has-dropdown">
                          <a
                            href="#"
                            className="elitehost-dropdown-main-element"
                          >
                            Our Services
                          </a>
                          <ul className="elitehost-submenu list-unstyled menu-pages">
                            <li className="nav-item">
                              <a className="nav-link" href="/our_services">
                                Mobile Recharge
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="/our_services">
                                DTH Recharge
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="/our_services">
                                Google Play Recharge
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="/our_services">
                                Utility Bill Payments
                              </a>
                            </li>
                            {/* <li className="nav-item">
                              <a className="nav-link" href="/our_services">
                                PAN Card Service
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="/our_services">
                                Insurance Service
                              </a>
                            </li> */}
                          </ul>
                        </li>
                        <li className="menu-item elitehost-has-dropdown">
                          <a
                            href="#"
                            className="elitehost-dropdown-main-element"
                          >
                            Pages
                          </a>
                          <ul className="elitehost-submenu list-unstyled menu-pages">
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="/terms_and_conditions"
                              >
                                Terms and Conditions
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="/privacy_policy">
                                Privacy Policy
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="/refund_policy">
                                Refund Policy
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="/grievance_policy">
                                Grievance Policy
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/contact_us"
                            className="elitehost-dropdown-main-element"
                          >
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  {/* FOR HEADER RIGHT */}
                </div>
                <div className="rts-header__right d-flex">
                  <a href="https://app.rupepro.in/login" className="login__btn">
                    Login
                  </a>
                  <button
                    id="menu-btn"
                    aria-label="Menu"
                    className="mobile__active menu-btn"
                  >
                    <i className="fa-sharp fa-solid fa-bars" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* HEADER AREA END */}
      </>
    </React.Fragment>
  );
};

export default Header;
