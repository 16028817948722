import React from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const StartHero = () => {
  return (
    <React.Fragment>
      <>
        {/* HERO BANNER */}
        <div className="hero__banner banner__background">
          <div className="container">
            <div className="row">
              <div
                className="hero__banner__wrapper d-flex flex-wrap flex-lg-nowrap gap-5 gap-lg-0 
          align-items-center justify-content-between px-lg-0"
              >
                {/* banner content */}
                <div className="hero__banner__content">
                  <h1 className="heading">Grow Your Business with RupePro!</h1>
                  <p className="description">
                    Access Mobile Recharges, DTH, Utility Payments, Insurance
                    and PAN Card Services—all in one place. Experience fast,
                    reliable, and secure solutions.
                  </p>

                  <div class="banner-btn">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.fispl.rupepro"
                      class="rts-btn btn__long white__bg secondary__color"
                    >
                      <i class="fab fa-google-play"></i> Download App
                    </a>
                  </div>
                </div>
                {/* banner content end */}
                {/* banner image */}
                <div className="hero__banner__image start-5 position-relative d-flex align-items-end">
                  <figure className="banner__image ">
                    <img src="/images/ruppro-services.gif" width={650} alt="" />
                  </figure>
                </div>
                {/* banner image end */}
              </div>
            </div>
          </div>
        </div>
        {/* HERO BANNER END */}
      </>
    </React.Fragment>
  );
};

export default StartHero;
