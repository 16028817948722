import React from "react";

const AboutCompany = () => {
  return (
    <React.Fragment>
      {/* App-Solution-Section-Start */}

      <section className="rts-about-area pt--120 pb--150">
        <div className="container">
          <div className="section-inner">
            <div className="row">
              <div className="col-lg-7">
                <div className="about-image-area">
                  <img
                    className="main-image"
                    src="/images/about-us.png"
                    width={500}
                    alt=""
                  />
                  <img
                    className="floating-image"
                    src="/images/payment-banner.jpg"
                    width={310}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about-content-area">
                  <h2 className="section-title">About RupePro</h2>
                  <p className="desc" style={{ textAlign: "justify" }}>
                    Welcome to RupePro, your trusted partner for comprehensive
                    digital services. Operated by Bongpe Payment Solution
                    Private Limited, RupePro is dedicated to empowering
                    retailers with seamless solutions for Mobile Recharges, DTH
                    Recharges, Utility Bill Payments, Google Play Recharges,
                    Insurance Services, and PAN Card processing.
                  </p>

                  <p className="desc" style={{ textAlign: "justify" }}>
                    With a vision to simplify digital transactions and enable
                    business growth, RupePro combines technology, reliability,
                    and efficiency. Our platform is designed to offer a
                    user-friendly experience, fast processing, and instant
                    commissions, helping retailers expand their service
                    portfolio and increase revenue.
                  </p>

                  <div class="btn__two primary__bg white__color">
                    <a href="/about_us">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* App-Solution-Section-end */}
    </React.Fragment>
  );
};

export default AboutCompany;
