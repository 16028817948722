import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import WhyChooseUs from "../Home/WhyChooseUs";
import Faq from "../Home/Faq";

// import Testimonials from "../Home/Testimonials";
// import GetInTouch from "../Home/GetInTouch";
// import WhyChooseUs from "../Home/WhyChooseUs";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - RupePro";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* Breadcrumb */}
        <div className="rts-breadcrumb-area body-bg-2">
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="row align-items-center">
                <div className="col-lg-6 order-change">
                  <div className="breadcrumb-content">
                    <h1 className="heading-title" style={{ maxWidth: 494 }}>
                      About Us
                    </h1>
                    <p className="desc">
                      Empowering Retailers with Seamless Digital Solutions for a
                      Smarter Tomorrow
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="breadcrumb-image-area">
                    <img
                      src="assets/images/banner/breadcrumb-05.webp"
                      width={515}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb-shape-area">
            <img src="assets/images/banner/breadcrumb-shape.svg" alt="" />
          </div>
        </div>
        {/* Breadcrumb END */}
        {/* About Us Start */}
        <section className="rts-about-area pt--120">
          <div className="container">
            <div className="section-inner">
              <div className="row">
                <div className="col-lg-7">
                  <div className="about-image-area">
                    <img
                      className="main-image"
                      src="/images/about-us.png"
                      width={500}
                      alt=""
                    />
                    <img
                      className="floating-image"
                      src="/images/payment-banner.jpg"
                      width={310}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="about-content-area">
                    <h2 className="section-title">About RupePro</h2>
                    <p className="desc" style={{ textAlign: "justify" }}>
                      Welcome to RupePro, your trusted partner in digital
                      services, powered by Bongpe Payment Solution Private
                      Limited. We are committed to empowering retailers with a
                      wide range of fast, secure, and user-friendly solutions,
                      transforming the way financial transactions and utility
                      services are delivered.
                    </p>
                    <div className="about-feature-area">
                      <ul>
                        <li>
                          <div className="icon">
                            <img src="assets/images/about/04.svg" alt="" />
                          </div>
                          <div className="text">
                            Fast, Safe and Secure Platform
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <img src="assets/images/about/03.svg" alt="" />
                          </div>
                          <div className="text">24/7 Support</div>
                        </li>
                      </ul>
                    </div>
                    <div className="feature-area-bottom">
                      <ul>
                        <li>Retailer Empowerment</li>
                        <li>Trusted Partner Network</li>
                        <li>No Hidden Fees</li>
                        <li>User-Friendly Platform</li>
                        <li>Instant Commission Payouts</li>
                        <li>24/7 Support</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About Us END */}
        {/* Journey Start */}
        <section className="rts-journey-area section__padding">
          <div className="container">
            <div className="section-title-area">
              <h2 className="section-title text-center">
                We empower Indian Retailers by providing the digital financial
                services.
              </h2>
              <p className="desc" style={{ textAlign: "justify" }}>
                Our platform enables retailers to offer essential services like
                Mobile Recharge, DTH Recharge, Utility Payments, Google Play
                Recharge, Insurance Services, and PAN Card services, all under
                one roof. With a focus on innovation and customer satisfaction,
                RupePro simplifies complex processes, making it easier for
                businesses to grow and thrive.
              </p>
              <p className="desc pt-2" style={{ textAlign: "justify" }}>
                Headquartered in Tripura, with a corporate office in West
                Bengal, we are driven by a vision to bridge the gap between
                service providers and consumers, ensuring convenience,
                efficiency, and trust. At RupePro, we believe in building strong
                partnerships with our retailers and agents, providing them with
                the tools, support, and competitive commissions needed to
                succeed in today’s fast-paced digital world. Join us as we
                continue to redefine the future of digital services and create
                opportunities for growth and success.
              </p>
            </div>
            <div className="bottom-counter-area pt--20">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="counter-wrapper">
                    <div className="icon">
                      <img
                        src="assets/images/about/05.svg"
                        width={100}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h2 className="title-main">
                        <span className="counter">500</span> <span>k</span>
                      </h2>
                      <p className="disc">
                        We empower 5 lakh <br /> retailers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="counter-wrapper">
                    <div className="icon">
                      <img
                        src="assets/images/about/06.svg"
                        width={100}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h2 className="title-main">
                        <span className="counter">1</span> <span>k</span>
                      </h2>
                      <p className="disc">
                        More than 1 thousands <br /> distributors.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="counter-wrapper">
                    <div className="icon">
                      <img
                        src="assets/images/about/05.svg"
                        width={100}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h2 className="title-main">
                        <span className="counter">50</span> <span>m</span>
                      </h2>
                      <p className="disc">
                        Currently serving 50 million <br /> customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About Us End */}

        <WhyChooseUs />

        <Faq />

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
