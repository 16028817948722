import React from "react";

import "./style.css";

const ServicesBoxIcon = (props) => {
  return (
    <>
      {/* HOSTING OPTION */}
      <div
        className={
          props.className
            ? `rts-hosting-type body-bg-2 pt--110 pb--120`
            : "rts-hosting-type pt--110 pb--120"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="section-title-area text-center">
                  <h2 className="section-title">
                    We Provide Digital Financial Services
                  </h2>
                  <p className="desc">
                    Comprehensive Digital Solutions Tailored for Your Business
                    Needs
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* hosting option */}
          <div className="row mt--50">
            <div className="col-lg-12">
              <div className="rts-hosting-inner">
                <div className="row g-5">
                  {/* single package */}
                  <div className="col-lg-4 col-md-6">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/mobile-recharge-icon.png" alt="" />
                      </div>
                      <a href="#" className="title">
                        Mobile Recharge
                      </a>
                      <p className="excerpt">
                        Enjoy seamless mobile recharges across all major telecom
                        operators with instant processing and competitive
                        commissions.
                      </p>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="col-lg-4 col-md-6">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/dish-tv-icon.png" alt="" />
                      </div>
                      <a href="#" className="title">
                        DTH Recharge
                      </a>
                      <p className="excerpt">
                        Offer easy and quick DTH recharges for all leading
                        providers, ensuring uninterrupted entertainment for your
                        customers.
                      </p>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="col-lg-4 col-md-6">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/google-play-icon.png" alt="" />
                      </div>
                      <a href="#" className="title">
                        Google Play Recharge
                      </a>
                      <p className="excerpt">
                        Enable customers to top up their Google Play balance
                        effortlessly, giving them access to apps, games, and
                        digital content.
                      </p>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="col-lg-4 col-md-6">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/electricity-bill-icon.png" alt="" />
                      </div>
                      <a href="#" className="title">
                        Utility Bill Payments
                      </a>
                      <p className="excerpt">
                        Simplify utility payments for electricity, water, gas,
                        and more, with fast processing and reliable updates.
                      </p>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/pan-card-icon.png" alt="" />
                      </div>
                      <a href="#" className="title">
                        PAN Card Service
                      </a>
                      <p className="excerpt">
                        Deliver fast and efficient PAN card services, including
                        new applications and corrections, backed by a smooth
                        process.
                      </p>
                    </div>
                  </div> */}
                  {/* single package end */}
                  {/* single package */}
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/insurance-icon.png" alt="" />
                      </div>
                      <a href="#" className="title">
                        Insurance Service
                      </a>
                      <p className="excerpt">
                        Provide access to a variety of insurance policies,
                        helping customers secure their future with trusted
                        coverage options.
                      </p>
                    </div>
                  </div> */}
                  {/* single package end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* HOSTING OPTION END */}
    </>

    // <section
    //   className="appie-service-area appie-service-3-area pt-90 pb-50"
    //   id="service"
    // >
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div className="col-lg-6">
    //         <div className="appie-section-title text-center">
    //           <h3 className="appie-title">Our Services</h3>
    //           Explore a wide range of recharge and payment solutions designed to
    //           <br />
    //           meet all your digital needs with speed, security, and convenience.
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div class="row" style={{ paddingTop: "15px" }}>
    //         <div class="col-md-4 col-sm-6">
    //           <div class="serviceBox blue">
    //             <div class="service-icon">
    //               <span>
    //                 <i class="fas fa-mobile"></i>
    //               </span>
    //             </div>
    //             <h3 class="title">Mobile Recharge</h3>
    //             <p class="description">
    //                offers quick, secure mobile recharges for all
    //               major telecom operators in India, ensuring seamless
    //               connectivity anytime, anywhere.
    //             </p>
    //           </div>
    //         </div>
    //         <div class="col-md-4 col-sm-6">
    //           <div class="serviceBox blue">
    //             <div class="service-icon">
    //               <span>
    //                 <i class="fas fa-satellite-dish"></i>
    //               </span>
    //             </div>
    //             <h3 class="title">DTH Recharge</h3>
    //             <p class="description">
    //                provides fast and easy DTH recharges for all
    //               major service providers, ensuring uninterrupted entertainment
    //               at your convenience.
    //             </p>
    //           </div>
    //         </div>
    //         <div class="col-md-4 col-sm-6">
    //           <div class="serviceBox blue">
    //             <div class="service-icon">
    //               <span>
    //                 <i class="fab fa-google-play"></i>
    //               </span>
    //             </div>
    //             <h3 class="title">Google Play Recharge</h3>
    //             <p class="description">
    //                offers quick and secure Google Play recharges,
    //               giving you instant access to apps, games, subscriptions, and
    //               more, anytime you need.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default ServicesBoxIcon;
