import React from "react";
import Footer from "../Templete/Footer";
//import NavBar from "../Templete/NavBar";
import AboutCompany from "./AboutCompany";
//import BusinessPartner from "./BusinessPartner";
//import DownloadApp from "./DownloadApp";
import StartHero from "./StartHero";
//import Testimonials from "./Testimonials";

import ServicesBoxIcon from "../OurServices/ServicesBoxIcon";

import Header from "../Templete/Header";
// import GetInTouch from "./GetInTouch";
// import HowItWorks from "./HowItWorks";
// import BusinessInfo from "./BusinessInfo";
import Faq from "./Faq";

const Home = () => {
  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header />
        <StartHero />
        <AboutCompany />
        <ServicesBoxIcon className={true} />
        {/* <BusinessInfo /> */}
        {/* <HowItWorks /> */}
        {/* <Testimonials />
        <GetInTouch /> */}
        <Faq />
        <Footer />
      </div>

      {/* <BusinessPartner />
      <AboutFormaxPay />
      <DownloadApp />
      */}
    </React.Fragment>
  );
};

export default Home;
